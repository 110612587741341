import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import i18n from 'i18next';
import { Flex, Typography, Row, Col, ColorModeProvider } from '@airbus/components-react';
import { Button, Chip, Divider, Spinner } from '@airbus/components-react';
import { ArrowBack, CheckCircle, PlayArrow, Cancel } from '@airbus/icons/react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { AppContext } from '../../AppContext';
import { updateAppVersionStatus } from '../../services/api/AppValidatorApi';
import { CustomLoadingButton } from '../../shared/components/CustomLoadingButton/CustomLoadingButton';
import { selectToken, selectTokenExpiryDate } from '../../redux/app/features/credentialsSlice';

import { handleDates, prettyDate } from '../../utils/DateUtils';
import { displayErrorMessage } from '../../utils/ErrorUtils';
import { displaySize } from '../../utils/StringUtils';
import { parseComment, parseName, parseValidationLabels } from '../../utils/Validations';
import { apiUrl, isTokenExpired } from '../../utils/ApplicationUtils';

import RejectBox from './RejectBox';

import { selectCurrentUser } from '../../redux/app/features/userSlice';
import { CurrentUser } from '../../models/CurrentUserModel';
import { IApiError, IError } from '../../models/AppModels';
import { ApplicationValidatorAPIMessage, Validation } from '../../models/ApplicationValidation';

import { APPLICATION_DETAILS_VALIDATION, APPLICATION_VALIDATION } from '../../constants/Validation';

import '../../i18n/config';
import classes from './ValidationAppDetails.module.css';

interface TooltipButtonProps {
    label: string;
    path: string;
}

const TooltipButton = ({ label, path }: Readonly<TooltipButtonProps>) => {
    return (
        <div className={classes.tooltip_container}>
            <Button
                size="large"
                variant="primary"
                className={classes.launch_button}
                onClick={() => window.open(`https://${path}`, '_blank')}
                icon={<PlayArrow />}>
                <span className={classes.launch_button_label}>{label}</span>
            </Button>
            <span className={classes.tooltip_text}>{label}</span>
        </div>
    );
};

function AppDetail() {
    const { setMessageBanner } = useContext(AppContext);

    const [toggleFetch, setToggleFetch] = useState<boolean>(false);
    const [enabledClick, setEnabledClick] = useState<boolean>(false);
    const [showPreviewButton, setShowPreviewButton] = useState<boolean>(false);

    const token = useSelector(selectToken);
    const tokenExpiryDate = useSelector(selectTokenExpiryDate);

    const history = useHistory();
    const location: any = useLocation();

    const { id } = useParams<{ id: string }>();

    const appVersionId = id;

    const currentUser: CurrentUser | undefined = useSelector(selectCurrentUser);
    const currentUserDomains: string[] = [];
    currentUser?.roles?.forEach(({ label }) => label.indexOf('app validator') !== -1 && currentUserDomains.push(label));
    const options = {
        headers: {
            Authorization: 'Bearer ' + (token ?? ''),
            'Content-Type': 'application/json;charset=UTF-8'
        }
    };

    const getApplication = () => {
        return useQuery<ApplicationValidatorAPIMessage>(
            ['get', 'app-validator', appVersionId, toggleFetch],
            () =>
                axios.get(`${apiUrl}/app-validator/${appVersionId}`, options).then(({ data }) => {
                    if (data.error) {
                        setMessageBanner({
                            isBanner: true,
                            message: `${data.error.message}`,
                            type: 'error'
                        });
                    } else {
                        const withDate = handleDates(data);

                        return withDate;
                    }
                }),
            {
                enabled: !isTokenExpired(tokenExpiryDate ?? 0),
                refetchOnWindowFocus: false,
                retry: false,
                onError: (err) => {
                    setMessageBanner({
                        isBanner: true,
                        message: displayErrorMessage(err as IApiError),
                        type: 'error'
                    });
                }
            }
        );
    };

    async function updateStatus(
        status: typeof APPLICATION_VALIDATION.VALIDATED | typeof APPLICATION_VALIDATION.REJECTED,
        comment?: string
    ) {
        application?.appVersionId &&
            (await updateAppVersionStatus(token, application?.appVersionId, status, comment)
                .then((response) => {
                    setEnabledClick(false);

                    if (response.data.error) {
                        setMessageBanner({
                            isBanner: true,
                            message: response.data.error.message,
                            type: 'error'
                        });
                    } else {
                        setToggleFetch(!toggleFetch);
                    }
                })
                .catch((error: IError) => {
                    setEnabledClick(false);

                    setMessageBanner({
                        isBanner: true,
                        message: error.message,
                        type: 'error'
                    });
                }));
    }

    const { isLoading, data } = getApplication();
    const application: ApplicationValidatorAPIMessage = handleDates(data);

    useQuery<void>(
        [],
        () =>
            axios.get(`${apiUrl}/app-preview/${application.appVersionId}`, options).then(({ data }) => {
                if (data.error) {
                    setMessageBanner({
                        isBanner: true,
                        message: `${data.error.message}`,
                        type: 'error'
                    });
                } else {
                    setShowPreviewButton(data.isPreviewEnabled);
                }
            }),
        {
            enabled: !isTokenExpired(tokenExpiryDate ?? 0) && !!application?.appVersionId && !showPreviewButton,
            refetchOnWindowFocus: false,
            refetchInterval: 10000,
            retry: false,
            onError: (err) => {
                setMessageBanner({
                    isBanner: true,
                    message: displayErrorMessage(err as IApiError),
                    type: 'error'
                });
            }
        }
    );

    const allComments = parseComment(application);
    const labelValidations = parseValidationLabels(application);

    return (
        <div className="center">
            <ColorModeProvider mode="dark">
                <div className="bg-primaryblue-90 main-page-title">
                    <div className="text-left">
                        <Typography variant="h2">{i18n.t('validation.title')}</Typography>
                    </div>
                </div>
            </ColorModeProvider>
            <div className={classes.page_container}>
                <Flex className={classes.page_flex_container} justify="space-around" wrap>
                    <div className={classes.back_button}>
                        <Button
                            icon={<ArrowBack />}
                            onClick={() => {
                                history.push(
                                    `/validation-center${location.state?.validationTab ? `?filter=${location.state.validationTab}` : ''}`
                                );
                            }}
                            variant="ghost">
                            Back to list
                        </Button>
                    </div>
                    {isLoading && <Spinner style={{ marginTop: '30vh' }} />}
                    {!isLoading && application && (
                        <div className={classes.info_container}>
                            <div className={classes.info_left}>
                                <div className={classes.left_margin}>
                                    <Flex wrap alignItems="center">
                                        <Typography variant="h4">
                                            <b>{application.name}</b>
                                        </Typography>
                                        <a style={{ marginLeft: '1rem' }} />
                                        <Typography variant="large" style={{ color: 'blue' }}>
                                            V {application.version}
                                        </Typography>
                                    </Flex>
                                    <Flex wrap alignItems="end">
                                        {!!showPreviewButton &&
                                            Array.from(new Set(application.serviceLinks.map(({ type }) => type))).map((key) =>
                                                application.serviceLinks
                                                    .filter(({ type }) => type === key)
                                                    .map(({ path, name }, index) => {
                                                        const label = i18n.t('validation.preview_button_label').replace('###$$$', '');
                                                        const labelButton = `${label} ${name}`;
                                                        return <TooltipButton label={labelButton} path={path} key={index} />;
                                                    })
                                            )}
                                        <Typography className={classes.grey_color} variant="small">
                                            VPN is required to launch preview
                                        </Typography>
                                    </Flex>
                                    <br />
                                    <Flex wrap alignItems="end" justify="space-between">
                                        {' '}
                                        <Typography variant="h5">Release Notes</Typography>
                                    </Flex>
                                    <Divider />
                                    Some static release notes
                                    <Typography className={classes.metadata_title} variant="h5">
                                        Metadata
                                    </Typography>
                                    <Divider />
                                    <Typography className={classes.grey_color} variant="small">
                                        Description
                                    </Typography>
                                    <p>{application.description}</p>
                                    <Row className={classes.no_margin} justify="space-evenly">
                                        <Col>
                                            <Typography className={classes.grey_color} variant="small">
                                                Application provider
                                            </Typography>
                                        </Col>
                                        <Col>
                                            <Typography className={classes.grey_color} variant="small">
                                                User Domain
                                            </Typography>
                                        </Col>
                                        <Col>
                                            <Typography className={classes.grey_color} variant="small">
                                                Size
                                            </Typography>
                                        </Col>
                                        <Col>
                                            <Typography className={classes.grey_color} variant="small">
                                                Creation Date
                                            </Typography>
                                        </Col>
                                    </Row>
                                    <Row className={classes.no_margin} justify="space-evenly">
                                        <Col>
                                            <b>{application.provider}</b>
                                        </Col>
                                        <Col>
                                            <b>
                                                {Array.from(application.serviceLinks.map(({ type }) => type).filter((x) => !!x)).join(
                                                    ' & '
                                                )}
                                            </b>
                                        </Col>
                                        <Col>
                                            <b>{displaySize(application.size)}</b>
                                        </Col>
                                        <Col>
                                            <b>{prettyDate(application.created_at)}</b>
                                        </Col>
                                    </Row>
                                    <Typography className={classes.app_validation_title} variant="h5">
                                        Application Validation
                                    </Typography>
                                    <Divider />
                                    <Row className={classes.no_margin} justify="space-evenly">
                                        <Col>
                                            <Typography className={classes.grey_color} variant="small">
                                                Pending Validation
                                            </Typography>
                                        </Col>
                                        <Col>
                                            <Typography className={classes.grey_color} variant="small">
                                                Validated
                                            </Typography>
                                        </Col>
                                        <Col>
                                            <Typography className={classes.grey_color} variant="small">
                                                Rejected
                                            </Typography>
                                        </Col>
                                    </Row>
                                    <Row className={classes.no_margin} justify="space-evenly">
                                        <Col>
                                            <ul className={`${classes.app_list} ${classes.pending_app_list}`}>
                                                {application.pendingValidations.map((domain: string, index: number) => (
                                                    <li key={index}>
                                                        <Chip label={domain} />
                                                    </li>
                                                ))}
                                            </ul>
                                        </Col>
                                        <Col>
                                            <ul className={`${classes.app_list} ${classes.done_app_list} ${classes.validated_app_list}`}>
                                                {labelValidations.validated.map((i: Validation) => (
                                                    <li key={`${i.user.name}-${i.role.label}-${i.created_at}`}>
                                                        <Chip label={`${i.role.label} - ${parseName(i.user.name)}`} />
                                                    </li>
                                                ))}
                                            </ul>
                                        </Col>
                                        <Col>
                                            <ul className={`${classes.app_list} ${classes.done_app_list} ${classes.rejected_app_list}`}>
                                                {labelValidations.rejected.map((i: Validation) => (
                                                    <li key={`${i.user.name}-${i.role.label}`}>
                                                        <Chip label={`${i.role.label} - ${parseName(i.user.name)}`} />
                                                    </li>
                                                ))}
                                            </ul>
                                        </Col>
                                    </Row>
                                    <div className={classes.validate_reject_buttons}>
                                        <CustomLoadingButton
                                            variant="success"
                                            onClick={async () => {
                                                setEnabledClick(true);
                                                await updateStatus(APPLICATION_VALIDATION.VALIDATED);
                                            }}
                                            enabledClick={enabledClick}
                                            startIcon={<CheckCircle />}
                                            disabled={application.isDisabled}
                                            label={
                                                application.validation_state === APPLICATION_DETAILS_VALIDATION.REJECTED
                                                    ? '(Re)validate'
                                                    : 'Validate'
                                            }
                                        />
                                        &nbsp;&nbsp;
                                        <RejectBox
                                            disabled={
                                                application.isDisabled || application.validation_state !== APPLICATION_VALIDATION.PENDING
                                            }
                                            onReject={(comment: string) => {
                                                updateStatus(APPLICATION_VALIDATION.REJECTED, comment);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={classes.info_right}>
                                <Typography className={classes.activities_comments} variant="h5">
                                    Activities and comments
                                </Typography>
                                <Divider />
                                <div className={classes.comments_scroll}>
                                    {allComments.length ? (
                                        allComments.map((c, index: number) => (
                                            <div key={index} className={classes.available}>
                                                {' '}
                                                <Flex alignItems="center" justify="start">
                                                    <div className={classes.icon}>
                                                        {c.state === APPLICATION_DETAILS_VALIDATION.REJECTED && (
                                                            <Cancel fontSize={22} color="red" />
                                                        )}
                                                        {c.state === APPLICATION_DETAILS_VALIDATION.VALIDATED && (
                                                            <CheckCircle fontSize={22} color="green" />
                                                        )}
                                                    </div>
                                                    <div>
                                                        {' '}
                                                        <b>
                                                            Application was{' '}
                                                            {c.state === APPLICATION_DETAILS_VALIDATION.VALIDATED
                                                                ? c.isRevalidated
                                                                    ? 'revalidated'
                                                                    : 'validated'
                                                                : 'rejected'}{' '}
                                                            by {c.by} - {c.roles?.join(', ')}
                                                        </b>
                                                        {c.comment && (
                                                            <>
                                                                <div className={classes.comment}>{c.comment}</div>
                                                            </>
                                                        )}
                                                        <Typography variant="xsmall">
                                                            <div className={classes.date_stamp}>{c.at}</div>
                                                        </Typography>
                                                    </div>
                                                </Flex>
                                            </div>
                                        ))
                                    ) : (
                                        <div className={classes.unavailable}>
                                            <i>No activity or comment, yet!</i>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {!isLoading && !application && <div>No application version to display</div>}
                </Flex>
            </div>
        </div>
    );
}

export default AppDetail;
